import { render, staticRenderFns } from "./OfflineTransaction.vue?vue&type=template&id=9b49f2b0&scoped=true&"
import script from "./OfflineTransaction.vue?vue&type=script&lang=ts&"
export * from "./OfflineTransaction.vue?vue&type=script&lang=ts&"
import style0 from "./OfflineTransaction.vue?vue&type=style&index=0&id=9b49f2b0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b49f2b0",
  null
  
)

export default component.exports