






























import OfflineTransactionTs from './OfflineTransactionTs';
export default class OfflineTransaction extends OfflineTransactionTs {}
